import createBackend from '~/api/backend'
import { ServerBackend } from '~/api/ServerBackend'

export default ({ $axios }, inject) => {
  const backendWithAxios = createBackend($axios)
  const backend = {
    categories: backendWithAxios('categories'),
    popularCategories: backendWithAxios('categories/?popular=true'),
    menuCategories: backendWithAxios('menu_categories'),
    featured_diagrams: backendWithAxios('featured_diagrams'),
    featured_categories: backendWithAxios('featured_categories'),
    before_after: backendWithAxios('before_after'),
    diagrams: backendWithAxios('diagrams'),
    slides: backendWithAxios('slides'),
    favourites: backendWithAxios('favourites'),
    search: backendWithAxios('search'),
    licenses: backendWithAxios('licenses'),
    cartItemsPrice: backendWithAxios('cart_items/price'),
    payment: backendWithAxios('payment'),
    order: backendWithAxios('order'),
    orderPayment: backendWithAxios('payment/order'),
    relatedDiagrams: backendWithAxios('related_diagrams'),
    plans: backendWithAxios('plans'),
    subscription: backendWithAxios('subscription'),
    subscriptionPayment: backendWithAxios('payment/subscription'),
    specialOffers: backendWithAxios('special_offers'),
    freeProduct: backendWithAxios('free_product'),
    freeSlides: backendWithAxios('free_slides'),
    reviews: backendWithAxios('reviews'),
    banners: backendWithAxios('banners'),
    contact: backendWithAxios('contact'),
    bundles: backendWithAxios('bundles'),
  }
  inject('backend', backend)
  inject('serverBackend', new ServerBackend($axios))
}
